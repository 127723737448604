import React from "react";
import styled from "styled-components";
import Background from "../images/5.png";
import { Container } from "./common";
import { Icon7, Icon6, Icon5 } from "../images/icons/index.jsx";
import { whatsupLink } from "../utils/links";

const Section = styled.section`
  background-color: var(--gray-brown);
  background-image: url(${Background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 110%;
  padding: 80px 0 160px;

  @media (max-width: 1000px) {
    background-repeat: repeat-y;
    padding: 80px 0 120px;
  }
`;

const Title = styled.h2`
  color: var(--light-beige);
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 52px;
  line-height: 65px;
  margin-bottom: 80px;

  @media (max-width: 1250px) {
    font-size: 45px;
    line-height: 56px;
  }
  @media (max-width: 1000px) {
    font-size: 38px;
    line-height: 48px;
  }

  @media (max-width: 800px) {
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 400px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const List = styled.ul`
  display: flex;
  gap: 50px;
  justify-content: center;

  @media (max-width: 1250px) {
    gap: 40px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
`;

const Item = styled.li`
  flex: 1;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--light-beige);
  box-shadow: 0px 6px 25px 3px rgba(48, 25, 26, 0.5);
  border-radius: 23px;
  padding: 50px 35px;

  @media (max-width: 1250px) {
    padding: 40px 28px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    max-width: auto;
    padding: 50px 45px;
  }
`;

const ItemTitle = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;

  @media (max-width: 1250px) {
    font-size: 20px;
    line-height: 27px;
  }
`;

const Icon = styled.img`
  margin-bottom: 35px;
`;

const ItemText = styled.p`
  height: 60px;
  text-align: start;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 45px;

  @media (max-width: 1250px) {
    font-size: 15px;
    line-height: 20px;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ItemPrice = styled.p`
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    font-size: 23px;
    line-height: 28px;
  }

  @media (max-width: 400px) {
    font-size: 22px;
    line-height: 27px;
  }
`;

const Amount = styled.p`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 17px;
`;

const ItemButton = styled.button`
  cursor: pointer;
  background-color: var(--dark-brown);
  border: none;
  border-radius: 70px;
  color: var(--light-beige);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 16px 16px;
  transition: 0.3s;

  :hover {
    background-color: var(--gray-brown);
  }
`;

const Price = () => {
  return (
    <Section id="price">
      <Container>
        <Title>Стоимость</Title>
        <List>
          <Item className="element-animation">
            <ItemTitle>Офлайн</ItemTitle>
            <Icon src={Icon5} />
            <ItemText>Личная встреча с психологом в Москве.</ItemText>
            <ItemPrice>3500 ₽ / 60 минут</ItemPrice>
            <a href={whatsupLink} target="_blank" rel="noreferrer">
              <ItemButton>Записаться</ItemButton>
            </a>
          </Item>
          <Item className="element-animation">
            <ItemTitle>Онлайн</ItemTitle>
            <Icon src={Icon6} />
            <ItemText>
              Видеозвонок в удобном для вас месенджере или программе на ПК.
            </ItemText>
            <ItemPrice>3000 ₽ / 60 минут</ItemPrice>
            <a href={whatsupLink} target="_blank" rel="noreferrer">
              <ItemButton>Записаться</ItemButton>
            </a>
          </Item>
          <Item className="element-animation">
            <ItemTitle>Пакет Онлайн</ItemTitle>
            <Icon src={Icon7} />
            <ItemText>
              Выгодное предложение для экономных, включает 4 консультации.
            </ItemText>
            <ItemPrice style={{ marginBottom: "5px" }}>
              2500 ₽ / 60 минут
            </ItemPrice>
            <Amount>(итого 10 000 ₽)</Amount>
            <a href={whatsupLink} target="_blank" rel="noreferrer">
              <ItemButton>Записаться</ItemButton>
            </a>
          </Item>
        </List>
      </Container>
    </Section>
  );
};

export default Price;
