import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ContainerNode = styled.div`
  max-width: var(--max-container-width);
  padding: 0 140px;
  margin: 0 auto;

  @media (max-width: 1250px) {
    padding: 0 100px;
  }

  @media (max-width: 800px) {
    padding: 0 70px;
  }

  @media (max-width: 800px) {
    padding: 0 50px;
  }

  @media (max-width: 400px) {
    padding: 0 35px;
  }
`;

const Container = ({ children }) => {
  return <ContainerNode>{children}</ContainerNode>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
