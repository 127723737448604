import React from "react";
import styled from "styled-components";
import { Container } from "./common";
import { Whatsup, Telegram, Instagram } from "../images/icons";
import { telegramLink, whatsupLink, instagramLink } from "../utils/links";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Link } from "react-scroll";

const Section = styled.section`
  padding: 60px 0;
  background-color: var(--dark-brown);
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LinksList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const LinksItem = styled.li``;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-size: 26px;
  line-height: 30px;
  color: var(--light-beige);
  transition: 0.3s;

  @media (max-width: 1250px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 28px;
  }

  :hover {
    font-size: 28px;
    color: var(--gray-brown);

    @media (max-width: 1250px) {
      font-size: 26px;
    }

    @media (max-width: 1000px) {
      font-size: 22px;
    }
  }
`;

const IconsList = styled.ul`
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  gap: 50px;
  width: 100%;
  @media (max-width: 800px) {
    justify-content: center;
    margin-bottom: 25px;
  }
`;

const IconsItem = styled.li``;

const Icon = styled.img`
  max-width: 55px;
  cursor: pointer;
  transition: 0.3s;

  @media (max-width: 400px) {
    max-width: 45px;
  }
  :hover {
    max-width: 75px;

    @media (max-width: 400px) {
      max-width: 55px;
    }
  }
`;

const ConactsBox = styled.div`
  flex: 1;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  text-align: end;

  @media (max-width: 800px) {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

const ContactText = styled.p`
  color: var(--light-beige);
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;

  @media (max-width: 1250px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const footerItems = [
  { text: "В начало", to: "header" },
  { text: "С чем я работаю", to: "requests" },
  { text: "Обо мне", to: "aboutMe" },
  { text: "Образование", to: "education" },
  { text: "Стоимость", to: "price" },
];

const Footer = () => {
  const { width } = useWindowDimensions();

  return (
    <Section id="footer">
      <Container>
        <Wrapper>
          {width > 800 ? (
            <LinksList>
              {footerItems.map((item, index) => {
                return (
                  <LinksItem key={index}>
                    <StyledLink to={item.to} duration={500}>
                      {item.text}
                    </StyledLink>
                  </LinksItem>
                );
              })}
            </LinksList>
          ) : null}

          <IconsList>
            <IconsItem>
              <a href={telegramLink} target="_blank" rel="noreferrer">
                <Icon src={Telegram} />
              </a>
            </IconsItem>
            <IconsItem>
              <a href={whatsupLink} target="_blank" rel="noreferrer">
                <Icon src={Whatsup} />
              </a>
            </IconsItem>
            <IconsItem>
              <a href={instagramLink} target="_blank" rel="noreferrer">
                <Icon src={Instagram} />
              </a>
            </IconsItem>
          </IconsList>
          <ConactsBox>
            <ContactText>+7 926 115 51 13</ContactText>
            <ContactText>info@vash-psiholog.com</ContactText>
          </ConactsBox>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Footer;
