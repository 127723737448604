import React from "react";
import styled from "styled-components";
import { Container } from "./common";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import {
  EducationImage1,
  EducationImage2,
  EducationImage3,
  EducationImage4,
  EducationImage5,
  EducationImage6,
  EducationImage7,
  EducationImage8,
  EducationImage9,
  EducationImage10,
} from "../images/education/index";
import Background from "../images/4.png";
import "../styles/swipper.css";
import "swiper/css";
import "swiper/css/pagination";

const Section = styled.section`
  background-color: var(--light-beige);
  background-image: url(${Background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0 120px;

  @media (max-width: 800px) {
    padding: 60px 0 100px;
  }
  @media (max-width: 400px) {
    padding: 80px 0 120px;
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  font-size: 52px;
  line-height: 64px;
  margin-bottom: 20px;

  @media (max-width: 1250px) {
    font-size: 45px;
    line-height: 56px;
  }
  @media (max-width: 1000px) {
    font-size: 38px;
    line-height: 48px;
  }

  @media (max-width: 800px) {
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 400px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const SlideConent = styled.img`
  max-width: 800px;
`;

const Education = () => {
  return (
    <Section id="education">
      <Container>
        <Title>Образование</Title>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          loop={true}
          autoplay={{ delay: 5000 }}
          className="mySwiper element-animation"
        >
          <SwiperSlide>
            <SlideConent src={EducationImage1} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage2} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage3} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage4} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage5} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage6} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage7} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage8} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage9} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideConent src={EducationImage10} />
          </SwiperSlide>
        </Swiper>
      </Container>
    </Section>
  );
};

export default Education;
