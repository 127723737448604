import React from "react";
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Quatters,
} from "../images/icons/index.jsx";
import frontImage from "../images/1.png";
import styled from "styled-components";
import Container from "./common/Container.jsx";
import useWindowDimensions from "../utils/useWindowDimensions";
import { whatsupLink } from "../utils/links.js";
import { Link } from "react-scroll";

const Section = styled.section`
  background-color: var(--common-white);
  padding-top: 90px;
  padding-bottom: 68px;

  @media (max-width: 800px) {
    padding-top: 45px;
  }

  @media (max-width: 400px) {
    padding-top: 30px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const TextConainer = styled.figure`
  position: relative;
  margin-top: 135px;
  margin-right: 75px;
  flex: 2;

  @media (max-width: 1250px) {
    margin-top: 50px;
  }

  @media (max-width: 1000px) {
    margin-right: 0px;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const MainText = styled.p`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  transition: 0.3s;

  @media (max-width: 1250px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (max-width: 1000px) {
    padding-top: 45px;
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
    padding-top: 30px;
  }

  @media (max-width: 400px) {
    padding-top: 20px;
    font-size: 16px;
    line-height: 20px;
  }
`;

const QuattersImg = styled.img`
  margin-right: 20px;
  height: 80px;
  width: 80px;
  margin-top: 90px;

  @media (max-width: 1250px) {
    margin-top: 10px;
  }

  @media (max-width: 1000px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 800px) {
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 400px) {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
`;

const TextSignature = styled.figcaption`
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: end;
  margin-top: 12px;
  text-align: end;

  @media (max-width: 1250px) {
    font-size: 20px;
  }

  @media (max-width: 1000px) {
    font-size: 18px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 30px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    margin-top: 10px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }
`;

const Image = styled.img`
  max-width: 420px;

  @media (max-width: 1250px) {
    max-width: 300px;
  }

  @media (max-width: 1000px) {
    max-width: 420px;
  }

  @media (max-width: 800px) {
    max-width: 350px;
  }

  @media (max-width: 600px) {
    max-width: 300px;
  }

  @media (max-width: 400px) {
    max-width: 250px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  background-color: var(--common-gray);
  border: none;
  border-radius: 70px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 16px 16px;
  transition: 0.3s;

  @media (max-width: 400px) {
    font-size: 16px;
  }

  :hover {
    background-color: var(--light-brown);
    color: var(--common-white);
  }
`;

const ButtonLight = styled(Link)`
  cursor: pointer;
  background-color: var(--common-gray);
  border: none;
  border-radius: 70px;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 12px;
  transition: 0.3s;

  @media (max-width: 800px) {
    font-size: 15px;
  }

  :hover {
    background-color: var(--light-brown);
    color: var(--common-white);
  }
`;

const ServiceList = styled.ul`
  margin-top: 120px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

const ServiceItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: end;

  @media (max-width: 1100px) {
    width: 50%;
    margin-bottom: 20px;
  }
`;

const NameHeader = styled.h2`
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 27px;
  margin-bottom: 50px;

  @media (max-width: 800px) {
    font-size: 24px;
  }

  @media (max-width: 400px) {
    font-size: 21px;
  }
`;

const IconComponent = styled.img``;

const FrontPage = () => {
  const { isBigMobile, width } = useWindowDimensions();
  return (
    <Section id="frontPage">
      <Container>
        {width < 1000 ? (
          <NameHeader className="element-animation">
            Психолог Елена Вашестова
          </NameHeader>
        ) : null}
        <ContentWrapper>
          {width > 1000 ? (
            <QuattersImg className="element-animation" src={Quatters} />
          ) : null}
          <TextConainer>
            <div style={{ display: "flex" }}>
              {width < 1000 ? (
                <QuattersImg className="element-animation" src={Quatters} />
              ) : null}
              <MainText className="element-animation">
                Сколько бы Вам не было лет – это самый подходящий возраст, чтобы
                любить, мечтать и радоваться жизни...
              </MainText>
            </div>
            <TextSignature className="element-animation">
              Олег Рой
            </TextSignature>
            <div className="element-animation">
              <a href={whatsupLink} target="_blank" rel="noreferrer">
                <Button>Записаться</Button>
              </a>
            </div>
          </TextConainer>
          <ImageContainer className="element-animation">
            <Image src={frontImage} loading="lazy" />
          </ImageContainer>
        </ContentWrapper>
        {!isBigMobile ? (
          <ServiceList>
            <ServiceItem className="element-animation">
              <IconComponent src={Icon1} />
              <ButtonLight to="requests" duration={500}>
                Личностная сфера
              </ButtonLight>
            </ServiceItem>
            <ServiceItem className="element-animation">
              <IconComponent src={Icon2} />
              <ButtonLight to="requests" duration={500}>
                Терапия отношений
              </ButtonLight>
            </ServiceItem>
            <ServiceItem className="element-animation">
              <IconComponent src={Icon3} />
              <ButtonLight to="requests" duration={500}>
                Кризисная терапия
              </ButtonLight>
            </ServiceItem>
            <ServiceItem className="element-animation">
              <IconComponent src={Icon4} />
              <ButtonLight to="requests" duration={500}>
                Профессиональная сфера
              </ButtonLight>
            </ServiceItem>
          </ServiceList>
        ) : null}
      </Container>
    </Section>
  );
};

export default FrontPage;
