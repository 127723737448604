import React, { useEffect } from "react";
import styled from "styled-components";
import {
  FrontPage,
  Header,
  Requests,
  AboutMe,
  Education,
  Price,
  Footer,
} from "./components/index";
import SwiperCore, { Autoplay } from "swiper";
import useWindowDimensions from "./utils/useWindowDimensions.jsx";

const Container = styled.div``;

const onEntry = (entry) => {
  entry.forEach((change) => {
    if (change.isIntersecting) {
      change.target.classList.add("element-show");
    }
  });
};

function App() {
  const { width } = useWindowDimensions();

  useEffect(() => {
    console.log("trigger");
    const options = {
      threshold: [0.5],
    };
    const observer = new IntersectionObserver(onEntry, options);
    const elements = document.querySelectorAll(".element-animation");
    for (let elm of elements) {
      elm.style.transition = `all ${
        Math.floor(Math.random() * (10 - 5 + 1) + 10) / 10
      }s`;
      observer.observe(elm);
    }
  }, [width]);

  SwiperCore.use([Autoplay]);
  return (
    <Container>
      <Header />
      <FrontPage />
      <Requests />
      <AboutMe />
      <Education />
      <Price />
      <Footer />
    </Container>
  );
}

export default App;
