import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "./common";
import { Cross, Burger } from "../images/icons";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Link } from "react-scroll";

const Section = styled.section`
  padding-top: 60px;
  background-color: var(--common-white);

  @media (max-width: 800px) {
    padding-top: 45px;
  }

  @media (max-width: 400px) {
    padding-top: 30px;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
`;

const Item = styled.li``;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  scroll-behavior: smooth;
  transition: 0.5s;

  @media (max-width: 1250px) {
    font-size: 16px;
  }

  @media (max-width: 1000px) {
    font-size: 14.5px;
    line-height: 24px;
  }

  @media (max-width: 800px) {
    font-size: 22px;
    line-height: 26px;
  }

  :hover {
    color: var(--light-brown);
    font-size: 20px;

    @media (max-width: 1250px) {
      font-size: 18px;
    }

    @media (max-width: 1000px) {
      font-size: 16px;
    }

    @media (max-width: 800px) {
      font-size: 24px;
      line-height: 26px;
    }
  }
`;

const BurgerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BurgerButton = styled.button`
  background-color: var(--common-white);
  cursor: pointer;
  transition: 0.3s;

  :focus {
    transform: rotate(90deg);
  }

  :focus {
    transform: rotate(180deg);
  }

  @media (max-width: 800px) {
    max-width: 40px;
  }

  @media (max-width: 400px) {
    width: 30px;
  }
`;

const MenuModal = styled.div`
  background-color: var(--common-white);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0.92;
  z-index: 1000;
`;

const MenuContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 150px;
`;

const CrossButton = styled.button`
  cursor: pointer;
  background-color: var(--common-white);
  position: absolute;
  top: 50px;
  right: 50px;
  transition: 0.7s;

  :hover {
    transform: rotate(360deg);
  }

  @media (max-width: 400px) {
    top: 30px;
    right: 30px;
  }
`;

const MenuModalList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Header = () => {
  const headerItems = [
    { text: "с чем я работаю", to: "requests" },
    { text: "обо мне", to: "aboutMe" },
    { text: "образование", to: "education" },
    { text: "стоимость", to: "price" },
    { text: "контакты", to: "footer" },
  ];

  const { isTablet } = useWindowDimensions();

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const hadnleBugrgerClick = () => {
    setMenuIsVisible(true);
  };

  return (
    <Section id="header">
      <Container>
        {isTablet ? (
          <BurgerContainer>
            {!menuIsVisible ? (
              <BurgerButton onClick={hadnleBugrgerClick}>
                <img src={Burger} alt="menu" />
              </BurgerButton>
            ) : null}
            {menuIsVisible ? (
              <MenuModal>
                <MenuContent>
                  <CrossButton onClick={() => setMenuIsVisible(false)}>
                    <img src={Cross} alt="close" />
                  </CrossButton>
                  <MenuModalList>
                    {headerItems.map((item, index) => {
                      return (
                        <Item key={index}>
                          <StyledLink
                            to={item.to}
                            duration={500}
                            onClick={() => setMenuIsVisible(false)}
                          >
                            {item.text}
                          </StyledLink>
                        </Item>
                      );
                    })}
                  </MenuModalList>
                </MenuContent>
              </MenuModal>
            ) : null}
          </BurgerContainer>
        ) : (
          <List>
            {headerItems.map((item, index) => {
              return (
                <Item key={index}>
                  <StyledLink to={item.to} duration={500}>
                    {item.text}
                  </StyledLink>
                </Item>
              );
            })}
          </List>
        )}
      </Container>
    </Section>
  );
};

export default Header;
