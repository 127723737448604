import React from "react";
import styled from "styled-components";
import { Container } from "./common";
import Image from "../images/3.png";
import useWindowDimensions from "../utils/useWindowDimensions";
import { whatsupLink } from "../utils/links";

const Section = styled.section`
  background-color: var(--common-white);
  position: relative;
  overflow: hidden;
`;

const BackgroundImageContainer = styled.div`
  margin: 0 auto;
  max-width: var(--max-container-width);
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 800px) {
    padding-top: 60px;
    padding-bottom: 55px;
  }
  @media (max-width: 400px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }
`;

const BackgroundImage = styled.img`
  max-width: 500px;
  position: absolute;
  right: -0px;
  transform: translateX(100px);
  top: 120px;

  @media (max-width: 1250px) {
    max-width: 400px;
    top: 250px;
  }

  @media (max-width: 1000px) {
    max-width: 350px;
    top: 300px;
  }

  @media (max-width: 800px) {
    margin: 20px 0;
    position: static;
    transform: translate(0, 0);
  }

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const InnerContainer = styled.div`
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1000px) {
    max-width: 57%;
  }

  @media (max-width: 800px) {
    align-items: center;
    max-width: 100%;
    text-align: center;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 58px;
  line-height: 70px;
  margin-bottom: 20px;

  @media (max-width: 1250px) {
    font-size: 50px;
    line-height: 60px;
  }
  @media (max-width: 1000px) {
    font-size: 42px;
    line-height: 50px;
  }

  @media (max-width: 800px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: 400px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const Subtitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: 1000px) {
    font-size: 15px;
    line-height: 20px;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

const TextWrapper = styled.div`
  background-color: var(--common-gray);
  border: 2.5px dashed var(--dark-brown);
  border-radius: 80px;
  margin-bottom: 40px;
  padding: 23px 60px;

  @media (max-width: 800px) {
    padding: 20px 40px;

    margin-bottom: 30px;
  }
  @media (max-width: 400px) {
    padding: 15px 20px;
    margin-bottom: 20px;
  }
`;

const BoldText = styled.p`
  display: inline;
  font-weight: 600;
`;

const Button = styled.div`
  cursor: pointer;
  background-color: var(--common-gray);
  border: none;
  border-radius: 70px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 16px;
  transition: 0.3s;

  :hover {
    background-color: var(--light-brown);
    color: var(--common-white);
  }
`;

const AboutMe = () => {
  const { width } = useWindowDimensions();

  return (
    <Section id="aboutMe">
      <BackgroundImageContainer>
        {width > 800 ? (
          <BackgroundImage src={Image} className="element-animation" />
        ) : null}
        <Container>
          <InnerContainer>
            <Title>Здравствуйте!</Title>
            <Subtitle className="element-animation">
              Меня зовут Елена. Я практикующий психолог.
            </Subtitle>
            {width < 800 ? (
              <BackgroundImage src={Image} className="element-animation" />
            ) : null}
            <div></div>
            <Text
              style={{ marginBottom: "40px" }}
              className="element-animation"
            >
              В профессии с 2013 года. Имею профильное высшее образование.
              Регулярно посещаю обучающие программы, прохожу супервизию и личную
              терапию, постоянно совершенствуюсь как специалист. Применяю
              интегративный подход: гештальт, НЛП, КПТ, регрессивный гипноз и
              др. в консультировании.
            </Text>

            <TextWrapper className="element-animation">
              <Text>
                <BoldText>Важными принципами моей работы являются:</BoldText>
                <br />
                доброжелательность, индивидуальный подход, бережное отношение к
                вашим чувствам, работа на результат, анонимность.
              </Text>
            </TextWrapper>

            <Text
              style={{ marginBottom: "27px" }}
              className="element-animation"
            >
              Психология неотъемлемая часть моей жизни. Все техники и методики
              отработаны практикой и могу заверить, что они работают! Начните
              менять свою жизнь к лучшему прямо сейчас!
            </Text>
            <Subtitle className="element-animation">
              Напишите мне, и мы вместе найдём решение вашей проблемы!
            </Subtitle>
            <div className="element-animation">
              <a href={whatsupLink} target="_blank" rel="noreferrer">
                <Button>Записаться</Button>
              </a>
            </div>
          </InnerContainer>
        </Container>
      </BackgroundImageContainer>
    </Section>
  );
};

export default AboutMe;
