import React from "react";
import styled from "styled-components";
import { Container } from "./common";
import Animation from "../animations/girl.gif";
import { Icon11, Icon12, Icon13, Icon14 } from "../images/icons/index.jsx";
import useWindowDimensions from "../utils/useWindowDimensions.jsx";

const Section = styled.section`
  background-color: #dcd1cb;
`;

const BackgroundImageContainer = styled.div`
  margin: 0 auto;
  max-width: 2000px;
  position: relative;
  padding-top: 55px;
  padding-bottom: 120px;

  @media (max-width: 1250px) {
    padding-bottom: 70px;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  @media (max-width: 800px) {
    padding-bottom: 70px;
  }
`;

const BackgroundImage = styled.img`
  max-width: 500px;
  position: absolute;
  right: 100px;
  bottom: 0;

  @media (max-width: 1250px) {
    max-width: 430px;
    right: 50px;
  }
`;

const Header = styled.h2`
  text-align: center;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 45px;

  @media (max-width: 1250px) {
    font-size: 42px;
    line-height: 51px;
  }

  @media (max-width: 1000px) {
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 44px;
  }

  @media (max-width: 800px) {
    margin-bottom: 35px;
    font-size: 30px;
    line-height: 37px;
  }

  @media (max-width: 400px) {
    margin-bottom: 30px;
    font-size: 25px;
    line-height: 30px;
  }
`;

const RequestList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  max-height: 600px;

  @media (max-width: 1000px) {
    flex-wrap: nowrap;
    max-height: 100%;
  }
`;

const RequestItem = styled.li`
  max-width: 50%;

  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

const RequestTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 800px) {
    font-size: 17px;
    line-height: 20px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const RequestWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 27px;
  border: 2.0363px dashed #30191a;
  border-radius: 65px;
  padding: 0 25px;
  height: 120px;

  @media (max-width: 1000px) {
    height: 100px;
    padding: 0 23px;
  }

  @media (max-width: 800px) {
    height: 80px;
    padding: 0 20px;
    gap: 20px;
  }

  @media (max-width: 600px) {
    height: 100%;
    padding: 15px 18px;
    gap: 20px;
  }

  @media (max-width: 400px) {
    height: 95px;
    padding: 0 13px;
    gap: 15px;
  }
`;

const RequestText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 1250px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 800px) {
    font-size: 13px;
    line-height: 16px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Icon = styled.img`
  @media (max-width: 800px) {
    max-width: 50px;
  }

  @media (max-width: 400px) {
    max-width: 40px;
  }
`;

const Requests = () => {
  const { width } = useWindowDimensions();
  console.log(width);
  return (
    <Section id="requests">
      <BackgroundImageContainer>
        {width > 1000 ? (
          <BackgroundImage src={Animation} className="element-animation" />
        ) : null}
        <Container>
          <Header>ЗАПРОСЫ, С КОТОРЫМИ РАБОТАЮ</Header>
          <RequestList>
            <RequestItem className="element-animation">
              <RequestTitle>личностная сфера</RequestTitle>
              <RequestWrapper>
                <Icon src={Icon11} />
                <RequestText>
                  тревога, страхи, апатия, проблемы с самооценкой,
                  психологические травмы, экзистенциальный кризис (кто я, зачем
                  живу и чего хочу?), сложно сказать &#34;нет&#34;
                </RequestText>
              </RequestWrapper>
            </RequestItem>
            <RequestItem className="element-animation">
              <RequestTitle>
                кризисная терапия (острые стрессовые состояния)
              </RequestTitle>
              <RequestWrapper>
                <Icon src={Icon13} />
                <RequestText>
                  тяжёлая болезнь, потеря близких, горе, утрата, стихийные
                  бедствия и катастрофы, ПТСР, вооружённые конфликты
                </RequestText>
              </RequestWrapper>
            </RequestItem>
            <RequestItem className="element-animation">
              <RequestTitle>профессиональная сфера</RequestTitle>
              <RequestWrapper>
                <Icon src={Icon14} />
                <RequestText>
                  конфликты с коллегами, отсутствие карьерного роста,
                  профессиональное выгорание, пашу, как &#34;бессмертный
                  пони&#34;, &#34;на работу, как на каторгу&#34;
                </RequestText>
              </RequestWrapper>
            </RequestItem>
            <RequestItem className="element-animation">
              <RequestTitle>семейная сфера</RequestTitle>
              <RequestWrapper>
                <Icon src={Icon12} />
                <RequestText>
                  ссоры в семье, одиночество, повторение неудачных отношений,
                  развод, созависимость, хочется замуж, сложные отношения с
                  родителями
                </RequestText>
              </RequestWrapper>
            </RequestItem>
          </RequestList>
        </Container>
      </BackgroundImageContainer>
    </Section>
  );
};

export default Requests;
